import ErrorView from '@/views/ErrorView.vue';
import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/LoginView.vue';
import VerifyView from '@/views/VerifyView.vue';
import SignupView from '@/views/SignupView.vue';
import ResetView from '@/views/ResetView.vue';
import PasswordView from '@/views/PasswordView.vue';

export default [
    {path: '/', name: 'home', component: HomeView},

    {path: '/registo', name: 'signup', component: SignupView, meta: {title: 'Nova Inscrição'}},
    {path: '/verificar/:id', name: 'verify', component: VerifyView, meta: {title: 'Verificar Inscrição'}},

    {path: '/entrar', name: 'login', component: LoginView, meta: {title: 'Entrar na Inscrição'}},

    {path: '/recuperar', name: 'reset', component: ResetView, meta: {title: 'Recuperar Palavra-Passe'}},
    {path: '/password/:id', name: 'password', component: PasswordView, meta: {title: 'Alterar Palavra-Passe'}},

    {path: '/perfil', name: 'profile', component: () => import('@/views/ProfileView.vue'), meta: {title: 'Perfil'}},

    {path: '/ensaio', name: 'songs', component: () => import('@/views/SongsView.vue'), meta: {title: 'Sala de Ensaio'}},
    {path: '/ensaio/:id', name: 'song', component: () => import('@/views/SongView.vue'), meta: {title: 'Sala de Ensaio'}},

    {path: '/equipa', name: 'about', component: () => import('@/views/AboutView.vue'), meta: {title: 'Membros'}},
    {path: '/perguntas', name: 'faq', component: () => import('@/views/FaqView.vue'), meta: {title: 'Perguntas Frequentes'}},
    {path: '/galeria', name: 'gallery', component: () => import('@/views/GalleryView.vue'), meta: {title: 'Galeria'}},
    {path: '/galeria/ensaios', name: 'gallery-album02', component: () => import('@/views/GalleryAlbum02View.vue'), meta: {title: 'Galeria - Ensaios'}},
    {path: '/galeria/xutos1000', name: 'gallery-album01', component: () => import('@/views/GalleryAlbum01View.vue'), meta: {title: 'Galeria - Xutos\'1000'}},
    {path: '/imprensa', name: 'press', component: () => import('@/views/PressView.vue'), meta: {title: 'Imprensa'}},
    {path: '/contactos', name: 'contact', component: () => import('@/views/ContactView.vue'), meta: {title: 'Contactos'}},
    {path: '/:catchAll(.*)', name: 'error', component: ErrorView}
];
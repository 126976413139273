<template>
  <NavbarComponent />
  <div class="home-bg">
    <div class="home-navbar">
    </div>
    <div class="home-display">
      <div class="container">
        <h1 class="display-3 lh-1">
          A maior banda rock de sempre em Portugal!
        </h1>
        <p class="lead">
          Ensaiamos os clássicos do rock português e damos concertos gigantes com 1000 músicos a tocar juntos em estádios cheios.
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <section v-if="!user" class="text-center mb-4">
      <i class="bi-music-note-beamed fs-l mx-3"></i>
      <i class="bi-tools fs-l mx-3"></i>
      <i class="bi-heart-fill fs-l mx-3"></i>
      <h3 class="display-6">
        Músicos, Voluntários e Fãs
      </h3>
      <p class="offset-md-2 fs-5">
        Precisamos de todos.
        Músicos para tocar e cantar, voluntários para ajudar a montar os concertos e fãs dedicados para trazer calor às bancadas.
        A inscrição é gratuita.
      </p>
      <p>
      <router-link :to="{name: 'signup'}" class="btn btn-primary btn-lg">
        <i class="d-none d-md-inline bi-person-fill"></i>
        Fazer Inscrição
      </router-link>
      </p>
    </section>

    <section v-if="user" class="text-center mb-4">
      <div class="row">
        <div class="col-md-2 offset-md-5 col-4 offset-4 text-center mb-4">
          <img :src="user.picture" class="img-fluid rounded-circle" :alt="user.firstName">
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="display-6 mb-4">
            Olá {{ user.firstName }}!
          </h1>
          <p>
          <router-link :to="{name: 'profile'}" class="btn btn-primary">
            <i class="d-none d-md-inline bi-person-fill"></i>
            Ver Inscrição
          </router-link>
          </p>
          <p class="small">
            <a class="" @click.prevent="logOut">
              Sair da Inscrição
            </a>
          </p>
        </div>
      </div>
    </section>

    <section class="row bg-secondary" style="margin-top: 6rem;">
      <div class="col-md-8 mmy-3 pt-3">
        <h2>
          <img :src="require('@/assets/x1k-logo.png')" style="width: 30%;" alt="Xutos'1000" />
        </h2>
        <p>
          Tudo começou em 2018 com o tributo aos Xutos & Pontapés e homenagem à memória do Zé Pedro.
          Foi um dia único que nos deu alento para mais esta iniciativa.
        </p>
        <p>
          <a href="https://www.youtube.com/watch?v=xpYrRiLY9Q0&t=1s" class="btn btn-dark" target="_bleank">
            <i class="bi-youtube"></i>
            Recorda o vídeo
          </a>
        <router-link :to="{name: 'about'}" class="btn btn-dark ms-2">
          <i class="bi-people-fill"></i>
          Conhece a Equipa
        </router-link>    
        </p>
      </div>
      <div class="col-md-4 my-3">
        <a href="https://www.youtube.com/watch?v=xpYrRiLY9Q0&t=1s" target="_bleank">
          <img :src="require('@/assets/event1-1600x900.jpg')" class="img-fluid" alt="Não sou o único" />
        </a>
      </div>
    </section>

    <section>
      <h2>
        Quando é o próximo concerto?
      </h2>
      <p>
        <strong>
          Tudo depende de termos músicos inscritos em número suficiente para tal.
        </strong>
        Ainda não há dia nem local, mas estamos a trabalhar para que tal aconteça muito brevemente e assim podermos anunciar a nossa 1ª data.
        Os concertos são anunciados com grande antecedência para que todos os músicos tenham tempo para se preparar.
      </p>
      <p>
      <router-link :to="{name: 'faq'}" class="btn btn-dark ms-2">
        <i class="bi-question-circle me-2"></i>
        Outras Perguntas Frequentes
      </router-link>    
      </p>
    </section>

    <section>
      <div class="row">
        <div class="col-12">
          <h2>
            Galeria
          </h2>
        </div>
      </div>

      <div v-for="album in albums" :key="album.path" class="row mb-3">
        <h5>
          {{ album.title }}
          <small>
            {{ album.date }}
          </small>
        </h5>
        <div v-for="n in 4" :key="n" class="col-6 col-md-3">
          <img :src="require('@/assets/gallery/' + album.path + '/' + album.pictures[n])" class="img-fluid" :alt="album.title" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-4">
          <router-link :to="{name: 'gallery'}" class="btn btn-dark ms-2">
            <i class="bi-image me-2"></i>
            Ver Todas
          </router-link>    
        </div>
      </div>
    </section>

    <section>
      <div class="row">

        <figure v-for="clip in clips" :key="clip.id" class="col-md-3 col-6">
          <img :src="require('@/assets/press/' + clip.logo)" class="img-fluid logo-press" :alt="clip.source" />
          <!--          
                    <img :src="require('@/assets/press/picture/' + clip.picture)" class="img-fluid mb-4" :alt="clip.title" />
                    <h3>
                      {{ clip.title }}
                    </h3>
          -->
          <blockquote class="blockquote">
            <p>{{ clip.quote }}</p>
          </blockquote>
          <figcaption class="blockquote-footer">
            <a :href="clip.url" target="_blank">
              {{ clip.source }}, {{ clip.date }}
            </a>
          </figcaption>
        </figure>

      </div>
    </section>

    <section>
      <SocialNetworks />
    </section>

  </div>
</template>

<style>
  .home-bg {
      background-image: url(../assets/picture.png);
      background-size: cover;
      background-position: bottom;
  }

  .home-navbar {
      background-image:
          linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
          url(../assets/1x1.png);
      padding-bottom: 5rem;
  }

  .home-display {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url(../assets/1x1.png);
      padding-top: 12rem;
      margin-bottom: 2rem;
  }

  .logo-press {
      max-height: 2.25rem;
      width: auto;
      margin-bottom: .5rem;
  }

</style>

<script>
  import NavbarComponent from '@/components/NavbarComponent.vue';
  import SocialNetworks from '@/components/SocialNetworks.vue';
  import albums from '@/data/albums.js';

  export default {
      name: 'HomeView',
      components: {
          NavbarComponent,
          SocialNetworks
      },
      computed: {
          user() {
              return this.$store.state.auth.user;
          }
      },
      data() {
          return {
              clips: [
                  {id: '47d107e9', url: 'https://www.noticiasmagazine.pt/2020/fas-antes-durante-e-para-la-do-%EF%AC%81m/historias/252295/', title: 'Fãs. Antes, durante e para lá do ﬁm', date: '2020-09-02', quote: 'A morte de Zé Pedro, dos Xutos & Pontapés, esteve na origem de uma homenagem ao guitarrista.', picture: 'magazine-20200902.png', source: 'Notícias Magazine', logo: 'magazine.png'},
                  {id: '09d397ca', url: 'https://www.tsf.pt/cultura/musica/nao-foram-as-unicas-mil-pessoas-homenageiam-os-xutos-em-almada-9724533.html', title: 'Não foram as únicas: mil pessoas homenagearam os Xutos. Veja o vídeo', date: '2018-08-18', quote: 'Cerca de mil pessoas juntaram-se para não serem os únicos, em Almada, a homenagear os Xutos & Pontapés e, em especial, Zé Pedro.', picture: 'tsf-20180816.png', source: 'TSF', logo: 'tsf.png'},
                  {id: 'f84119d9', url: 'https://www.dn.pt/cultura/homenagem-xutos-1000-em-video--9733372.html', title: 'Homenagem Xutos\'1000 em vídeo', date: '2018-08-10', quote: 'Fãs dos Xutos & Pontapés juntaram-se há um mês no Campo de Jogos do Pragal, em Almada para homenagear Zé Pedro e, com ele, os Xutos & Pontapés. É o projeto Xutos\'1000, que agora chega ao fim com a publicação do vídeo.', picture: 'dn-20180818.png', source: 'Diário de Notícias', logo: 'dn2.png'},
                  {id: 'ecae496a', url: 'https://www.rtp.pt/noticias/cultura/evento-quer-juntar-cerca-de-1000-musicos-para-tocar-o-tema-nao-sou-o-unico_v1054420', title: 'Evento quer juntar cerca de 1000 músicos para tocar o tema "Não Sou o Único"', date: '2018-01-25', quote: 'Cerca de mil músicos vão juntar-se para tocar o tema "Não Sou o Único" dos Xutos e Pontapés. A iniciativa está prevista para o verão e pretende ser uma homenagem a Zé Pedro.', picture: 'rtp-20180125.png', source: 'RTP 1', logo: 'rtp.png'}
              ],
              albums: albums
          };
      },
      methods: {
          logOut() {
              this.$store.dispatch('auth/logout');
              this.$router.push({name: 'home'});
          }
      }
  }
</script>

<template>
  <LogoWithClose></logowithclose>

  <div class="container">
    <h1 class="display-6 mb-4">
      Alterar Palavra-Passe
    </h1>

    <div class="row">
      <div class="col-md-8 mb-4">
        <p class="lead">
          Enviámos uma mensagem para o teu endereço de email a qual contém um código de verificação que tens de indicar aqui para alterar a palavra-passe.
        </p>

        <p v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </p>

        <Form @submit="handlePassword">
          <div class="form-group mb-3">
            <label for="pin" class="form-label">
              Código de Verificação
            </label>
            <Field type="text" name="pin" id="pin" class="form-control" rules="required|digits|min:6|max:6"></Field>
            <Field type="hidden" name="code" :value="$route.params.id" rules="required"></Field>
            <ErrorMessage name="pin" class="text-danger"></Errormessage>
          </div>

          <div class="mb-3">
            <label for="password" class="form-label">Nova Palavra-Passe</label>
            <Field type="password" name="password" id="password" class="form-control" rules="required|min:8"></Field>
            <ErrorMessage name="password" class="text-danger"></Errormessage>
          </div>

          <div class="mb-3">
            <label for="confirmPassword" class="form-label">Confirmar Palavra-Passe</label>
            <Field type="password" name="confirmPassword" id="confirmPassword" class="form-control" rules="required|min:8|match:password,Palavra-Passe"></Field>
            <ErrorMessage name="confirmPassword" class="text-danger" ></Errormessage>
          </div>

          <div class="form-group mb-3">
            <button class="btn btn-primary" :disabled="loading">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              <span>
                Guardar
              </span>
            </button>
            <router-link :to="{name: 'home'}" class="btn btn-outline-primary ms-2">
              Cancelar
            </router-link>
          </div>
        </Form>

      </div>
      <div class="col-md-4 mb-4">
        <GetHelp></Gethelp>
      </div>
    </div>
  </div>
</template>

<script>
  import LogoWithClose from '@/components/LogoWithClose.vue';
  import GetHelp from '@/components/GetHelp.vue';
  import { Form, Field, ErrorMessage } from "vee-validate";
  import AuthService from '@/services/AuthService.js';

  export default {
      name: 'PasswordView',
      components: {
          LogoWithClose,
          GetHelp,
          Form,
          Field,
          ErrorMessage
      },
      data() {
          return {
              loading: false,
              message: ''
          };
      },
      methods: {
          handlePassword(values) {
              this.loading = true;

              AuthService.password(values)
                      .then(() => {
                          this.$store.commit('flash', {type: 'success', message: 'A palavra-passe foi alterada com sucesso. Já podes entrar na inscrição!'});
                          this.$router.push({name: 'login'});
                      })
                      .catch(() => {
                          this.loading = false;
                          this.message = 'Não foi possível alterar a palavra-passe. Por favor tenta novamente ou contacta-nos para obteres ajuda.';
                      });
          }
      }
  };
</script>
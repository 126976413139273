<template>
  <LogoWithClose></logowithclose>

  <div class="container">
    <h1 class="display-6 mb-4">
      Entrar na Inscrição
    </h1>

    <FlashMessage></Flashmessage>

    <div v-if="message" class="alert alert-danger" role="alert">
      {{ message }}
    </div>

    <div class="row">
      <div class="col-md-8 mb-4">
        <Form @submit="handleLogin" :validation-schema="schema">
          <div class="form-group mb-3">
            <label for="email" class="form-label">
              Endereço de Email
            </label>
            <Field name="email" type="email" class="form-control"></Field>
            <ErrorMessage name="email" class="text-danger"></Errormessage>
          </div>

          <div class="form-group mb-3">
            <label for="password" class="form-label">
              Palavra-Passe
            </label>
            <Field name="password" type="password" class="form-control"></Field>
            <ErrorMessage name="password" class="text-danger"></Errormessage>
          </div>

          <div class="form-group mb-3">
            <button class="btn btn-primary" :disabled="loading">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              <span>
                Entrar
              </span>
            </button>
            <router-link :to="{name: 'home'}" class="btn btn-outline-primary ms-2">
              Cancelar
            </router-link>
          </div>
        </Form>

        <ul class="list-unstyled">
          <li>
          <router-link :to="{ name: 'reset' }">
            Recuperar Palavra-Passe
          </router-link>
          </li>
          <li>
          <router-link :to="{name: 'signup'}">
            Fazer Nova Inscrição
          </router-link>
          </li>
        </ul>
      </div>
      <div class="col-md-4 mb-4">
        <GetHelp></Gethelp>
      </div>
    </div>
  </div>
</template>

<script>
  import LogoWithClose from '@/components/LogoWithClose.vue';
  import GetHelp from '@/components/GetHelp.vue';
  import FlashMessage from '@/components/FlashMessage.vue';
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from "yup";

  export default {
      name: 'LoginView',
      components: {
          LogoWithClose,
          GetHelp,
          FlashMessage,
          Form,
          Field,
          ErrorMessage
      },
      data() {
          const schema = yup.object().shape({
              email: yup.string().email('Este valor tem de ser um endereço de email váçido.').required('Este campo é obrigatório.'),
              password: yup.string().min(8, 'Este valor deve conter pelo menos 8 caracteres.').required('Este campo é obrigatório.'),
          });

          return {
              loading: false,
              message: '',
              schema
          };
      },
      computed: {
          authenticated() {
              return this.$store.state.auth.authenticated;
          },
          goto() {
              const goto = this.$store.state.goto;
              if (goto) {
                  this.$store.commit('goto');
              }
              return goto;
          }
      },
      created() {
          if (this.authenticated) {
              this.$router.push({name: 'profile'});
          }
      },
      methods: {
          handleLogin(user) {
              this.loading = true;

              this.$store.dispatch('auth/login', user)
                      .then(() => {
                          this.$router.push({name: this.goto || 'profile'});
                      })
                      .catch(() => {
                          this.loading = false;
                          this.message = 'O endereço de email não é válido ou a palavra-passe está incorreta.';
                      });
          },
      },
  };
</script>
<template>
  <LogoWithClose></Logowithclose>
  <div class="container">
    <h1 class="display-6">
      Nova Inscrição
    </h1>

    <div v-if="message" class="alert alert-danger" role="alert">
      {{ message }}
    </div>

    <div class="row">
      <div class="col-md-8 mb-4">
        <Form @submit="onSubmit">
          <div class="mb-3">
            <label class="form-label">
              Tipo de Inscrição
            </label>

            <div v-for="(type, id) in types" :key="id" class="form-check">
              <Field name="type" type="radio" :value="id" class="form-check-input" :id="'type' + id" rules="required"></Field>
              <label :for="'type' + id" class="form-check-label">
                <i :class="type.icon" class="me-2"></i>
                {{ type.name }}
              </label>
            </div>

            <ErrorMessage name="type" class="text-danger"></Errormessage>
          </div>

          <div class="mb-3">
            <label for="firstName" class="form-label">Nome Próprio</label>
            <Field type="text" name="firstName" id="firstName" class="form-control" rules="required|max:50"></Field>
            <ErrorMessage name="firstName" class="text-danger"></Errormessage>
          </div>

          <div class="mb-3">
            <label for="lastName" class="form-label">Apelido(s)</label>
            <Field type="text" name="lastName" id="lastName" class="form-control" rules="required|max:50"></Field>
            <ErrorMessage name="lastName" class="text-danger"></Errormessage>
          </div>

          <div class="mb-3">
            <label for="email" class="form-label">Endereço de Email</label>
            <Field type="email" name="email" id="email" class="form-control" rules="required|email|validateEmail"></Field>
            <ErrorMessage name="email" class="text-danger"></Errormessage>
          </div>

          <div class="mb-3">
            <label for="password" class="form-label">Criar Palavra-Passe</label>
            <Field type="password" name="password" id="password" class="form-control" rules="required|min:8"></Field>
            <ErrorMessage name="password" class="text-danger"></Errormessage>
          </div>

          <div class="mb-3">
            <label for="confirmPassword" class="form-label">Confirmar Palavra-Passe</label>
            <Field type="password" name="confirmPassword" id="confirmPassword" class="form-control" rules="required|min:8|match:password,Palavra-Passe"></Field>
            <ErrorMessage name="confirmPassword" class="text-danger" ></Errormessage>
          </div>

          <div class="form-check mb-3">
            <Field v-slot="{ field }" name="agreement" type="checkbox" :value="true" rules="checked">
              <label for="agreement" class="small">
                <input class="form-check-input" type="checkbox" name="agreement" id="agreement" v-bind="field" value="1">
                Autorizo que todos os meus dados pessoais aqui fornecidos sejam utilizados exclusivamente na gestão da minha participação na BANDA'1000, os quias não serão partilhados com entidades externas ao projeto.
              </label>
            </Field>
            <ErrorMessage name="agreement" class="text-danger" ></Errormessage>
          </div>

          <div class="form-check mb-3">
            <Field v-slot="{ field }" name="rights" type="checkbox" :value="true" rules="checked" v-model="rights">
              <label for="rights" class="small">
                <input class="form-check-input" type="checkbox" name="rights" id="rights" v-bind="field" value="1">
                Concordo que o promotor da BANDA'1000 seja titular de todos os direitos de imagem relacionados com a minha participação no projeto, os quais ficam desde já por mim expressamente cedidos.
              </label>
            </Field>
            <ErrorMessage name="rights" class="text-danger" ></Errormessage>
          </div>

          <button class="btn btn-primary">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>
              Submeter
            </span>
          </button>
          <router-link :to="{name: 'home'}" class="btn btn-outline-primary ms-2">
            Cancelar
          </router-link>

        </Form>
      </div>

      <div class="col-md-4 mb-4">
        <h3>
          Já fizesta a inscrição?
        </h3>
        <p>
          Para regressares á tua inscrição segue esta opção:
        </p>
        <router-link :to="{name: 'login'}" class="btn btn-secondary mb-4">
          Entrar na Inscrição
        </router-link>

        <GetHelp></Gethelp>
      </div>
    </div>
  </div>
</template>

<script>
  import LogoWithClose from '@/components/LogoWithClose.vue';
  import GetHelp from '@/components/GetHelp.vue';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import { defineRule } from 'vee-validate';
  import AuthService from '@/services/AuthService.js';
  import axios from 'axios';

  defineRule('validateEmail', async (value) => {
      const url = process.env.VUE_APP_API_HOST + '/emails/lookup';
      const data = {email: value};

      try {
          const response = await axios.post(url, data);
          return response.data.valid ? true : 'Este endereço de email já foi registado.';
      } catch (error) {
          location.href = '/erro';
      }
  });

  defineRule('checked', (value) => {
      if (value) {
          return true;
      }

      return 'Este campo é de preenchimento obrigatório.';
  });

  export default {
      name: 'SignupView',
      components: {
          LogoWithClose,
          GetHelp,
          Form,
          Field,
          ErrorMessage
      },
      data() {
          return {
              agreement: false,
              rights: false,
              loading: false,
              message: '',
              types: {
                  M: {name: 'Músico', icon: 'bi-music-note-beamed'},
                  V: {name: 'Voluntário', icon: 'bi-tools'},
                  F: {name: 'Fã', icon: 'bi-heart-fill'},
              }
          };
      },
      computed: {
          authenticated() {
              return this.$store.state.auth.authenticated;
          },
          goto() {
              const goto = this.$store.state.goto;
              if (goto) {
                  this.$store.commit('goto');
              }
              return goto;
          }
      },
      created() {
          if (this.authenticated) {
              AuthService.logout();
          }
      },
      methods: {
          onSubmit(values) {
              this.loading = true;

              AuthService.signup(values)
                      .then((data) => {
                          this.$router.push({name: 'verify', params: {id: data.id}});
                      })
                      .catch(() => {
                          this.loading = false;
                          this.message = 'Lamentamos mas não foi possível registar a tua inscrição.';
                      });
          }
      }
  };
</script>
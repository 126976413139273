<template>
  <h3>
    Obter ajuda
  </h3>
  <p>
    Se precisares de ajuda ou para qualquer esclarecimento adiocional não hesites em contactar-nos.
  </p>
  <router-link :to="{name: 'contact'}" class="btn btn-secondary mb-4">
    Contactar a Equipa
  </router-link>
</template>

<style scoped lang="scss">
</style>

<script>
  export default {
      name: 'GetHelp'
  };
</script>
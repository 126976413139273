<template>
  <nav class="container my-3">
    <div class="row">
      <div class="col-12">
        <router-link :to="{name: 'home'}">
          <img :src="require('@/assets/b1k-logo-wt.svg')" alt="BANDA'1000" />
        </router-link>
        <router-link :to="{name: route || 'home'}" class="float-end text-light text-lg">
          <i class="bi-x-lg"></i>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
  img {
      height: 2rem;
      width: auto;
  }
  .text-lg {
      font-size: 1.25rem;
      padding: .5rem;
  }
</style>

<script>
  export default {
      name: 'LogoWithClose',
      props: {
          route: String
      }
  }
</script>

<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <div class="container">
      <router-link :to="{ name: 'home'}" class="navbar-brand mb-2">
        <img :src="require('@/assets/b1k-logo-wt.svg')" style="width: auto; height: 2rem;" alt="BANDA'1000" />
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse nav-lg" id="navbar">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
          <router-link :to="{ name: 'home' }" active-class="active" class="nav-link">
            Início
          </router-link>
          </li>
          <li class="nav-item">
          <router-link :to="{ name: 'about' }" active-class="active" class="nav-link">
            Quem Somos
          </router-link>
          </li>
          <li class="nav-item">
          <router-link :to="{ name: 'faq' }" active-class="active" class="nav-link">
            Perguntas
          </router-link>
          </li>
          <li class="nav-item">
          <router-link :to="{ name: 'gallery' }" active-class="active" class="nav-link">
            Galeria
          </router-link>
          </li>
          <li class="nav-item">
          <router-link :to="{ name: 'press' }" active-class="active" class="nav-link">
            Imprensa
          </router-link>
          </li>
          <li class="nav-item">
          <router-link :to="{ name: 'contact' }" active-class="active" class="nav-link">
            Contacto
          </router-link>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li v-if="!user" class="nav-item">
          <router-link :to="{ name: 'login' }" class="btn btn-primary nav-link">
            <i class="d-none d-md-inline bi-person-fill"></i>
            Entrar
          </router-link>
          </li>
          <li v-if="user" class="nav-item">
          <router-link :to="{ name: 'profile' }" class="nav-link">
            <img :src="user.picture" class="rounded-circle img-fluid" :alt="user.firstName" style="width: auto; height: 2.5rem;">
          </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
      name: 'NavbarComponent',
      computed: {
          user() {
              return this.$store.state.auth.user;
          }
      },
  };
</script>
<template>
  <LogoWithClose/>
  <div class="container">
    <div class="col-md-6 offset-md-3 text-center">
      <i class="bi-bug fs-xl"></i>
      <h1 class="display-6">Oops!</h1>
      <p class="lead">
        Qualquer coisa correu mal aqui... Pedimos desculpa e vamos tentar resolver o mais depressa possível.
      </p>
      <a href="/" class="btn btn-primary">
        <i class="bi-house-fill"></i>
        Voltar ao Início        
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>

<script>
  import LogoWithClose from '@/components/LogoWithClose.vue';

  export default {
      name: 'ErrorView',
      components: {
          LogoWithClose
      }
  };
</script>
export default [
    {title: 'Ensaios', date: 'Abril/Maio de 2023', path: 'album02', pictures: [
            'picture01.png',
            'picture02.png',
            'picture03.png',
            'picture04.png',
            'picture05.png',
            //'picture06.png',
            'picture07.png',
            'picture08.png',
            'picture09.png',
            //'picture10.png',
            //'picture11.png',
            'picture12.png',
            'picture13.png',
            'picture14.png',
            'picture15.png',
            'picture16.png',
            'picture17.png',
            'picture18.png',
            'picture19.png',
            'picture20.png',
            'picture21.png',
            'picture22.png',
            'picture23.png',
            'picture24.png',
            'picture25.png',
            'picture26.png',
            'picture27.png',
            'picture28.png',
            'picture29.png',
            'picture30.png',
            'picture31.png',
            //'picture32.png',
            'picture33.png',
            'picture34.png',
            'picture35.png',
            'picture36.png',
            'picture37.png',
            'picture38.png',
            'picture39.png',
            'picture40.png',
            'picture41.png',
            'picture42.png',
        ]},
    {title: 'Xutos\'1000 ao vivo em Almada', date: '7 de Julho de 2018', path: 'album01', pictures: [
            'picture01.png',
            //'picture02.png',
            'picture03.png',
            'picture04.png',
            'picture05.png',
            'picture06.png',
            'picture07.png',
            'picture08.png',
            'picture09.png',
            'picture10.png',
            'picture11.png',
            'picture12.png',
            'picture13.png',
            'picture14.png',
            'picture15.png',
            'picture16.png',
            'picture17.png',
            'picture18.png',
            'picture19.png',
            'picture20.png',
            //'picture21.png',
            'picture22.png',
            'picture23.png',
            'picture24.png',
            'picture25.png',
            'picture26.png',
            'picture27.png',
            'picture28.png',
            'picture29.png',
            'picture30.png',
            'picture31.png',
            'picture32.png',
            'picture33.png',
            'picture34.png',
            'picture35.png',
            'picture36.png',
            'picture37.png',
            'picture38.png',
            //'picture39.png',
            'picture40.png',
            'picture41.png',
            'picture42.png',
            'picture43.png',
            'picture44.png',
            'picture45.png',
            'picture46.png',
        ]}
];
import AuthService from '@/services/AuthService.js';

export const auth = {
    namespaced: true,
    state: AuthService.init(),
    actions: {
        login( { commit }, user) {
            return AuthService.login(user)
                    .then(jwt => {
                        commit('login', jwt);
                        return Promise.resolve(jwt);
                    },
                            error => {
                                commit('logout');
                                return Promise.reject(error);
                            });
        },
        refresh( { commit }, token) {
            return AuthService.refresh(token)
                    .then(jwt => {
                        commit('login', jwt);
                        return Promise.resolve(jwt);
                    },
                            error => {
                                commit('logout');
                                return Promise.reject(error);
                            });
        },
        logout( { commit }) {
            AuthService.logout();
            commit('logout');
        }
    },
    getters: {
        expired(state) {
            return state.expires < Date.now();
        }
    },
    mutations: {
        login(state, jwt) {
            state.authenticated = true;
            state.jwt = jwt;

            const claims = AuthService.decode(jwt.accessToken);
            state.expires = claims.exp * 1000;
            state.user = {
                id: claims.sub,
                firstName: claims.ufn,
                lastName: claims.uln,
                picture: claims.upp,
                roles: claims.rol
            };
        },
        logout(state) {
            state.authenticated = false;
            state.expires = Date.now(),
            state.jwt = null;
            state.user = null;
        }
    }
};
<template>
  <p v-if="flash && flash.message" :class="'alert alert-' + flash.type">
    <i v-if="flash.type === 'success'" class="bi-check-lg me-2"></i>
    <i v-if="flash.type === 'warning'" class="bi-exclamation-triangle me-2"></i>
    <i v-if="flash.type === 'info'" class="bi-info-circle me-2"></i>
    <i v-if="flash.type === 'danger'" class="bi-x-lg me-2"></i>
    {{ flash.message }}
  </p>
</template>

<script>
  export default {
      name: 'FlashMessage',
      computed: {
          flash() {
              const flash = this.$store.state.flash;
              if (flash) {
                  this.$store.commit('flash');
              }
              return flash;
          }
      }
  };
</script>
<template>
  <LogoWithClose></logowithclose>

  <div class="container">
    <h1 class="display-6 mb-4">
      Recuperar Palavra-Passe
    </h1>

    <div class="row">
      <div class="col-md-8 mb-4">
        <p class="lead">
          Por favor indica aqui o endereço de email que usaste na inscrição de modo a poderes receber instruções para recuperar ou alterar a tua palavra-passe.
        </p>

        <p v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </p>

        <Form @submit="handleReset">
          <div class="form-group mb-3">
            <label for="email" class="form-label">
              Endereço de Email
            </label>
            <Field type="email" name="email" id="email" class="form-control" rules="required|email"></Field>
            <ErrorMessage name="email" class="text-danger"></Errormessage>
          </div>

          <div class="form-group mb-3">
            <button class="btn btn-primary" :disabled="loading">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              <span>
                Recuperar
              </span>
            </button>
            <router-link :to="{name: 'home'}" class="btn btn-outline-primary ms-2">
              Cancelar
            </router-link>
          </div>
        </Form>

      </div>
      <div class="col-md-4 mb-4">
        <GetHelp></Gethelp>
      </div>
    </div>
  </div>
</template>

<script>
  import LogoWithClose from '@/components/LogoWithClose.vue';
  import GetHelp from '@/components/GetHelp.vue';
  import { Form, Field, ErrorMessage } from "vee-validate";
  import AuthService from '@/services/AuthService.js';

  export default {
      name: 'ResetView',
      components: {
          LogoWithClose,
          GetHelp,
          Form,
          Field,
          ErrorMessage
      },
      data() {
          return {
              loading: false,
              message: ''
          };
      },
      methods: {
          handleReset(values) {
              this.loading = true;

              AuthService.reset(values)
                      .then((data) => {
                          this.$router.push({name: 'password', params: {id: data.id}});
                      })
                      .catch(() => {
                          this.loading = false;
                          this.message = 'O endereço de email não é válido.';
                      });
          }
      }
  };
</script>
import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes.js';
import store from '@/store';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({left: 0, top: 0})
            }, 500);
        });
    }
});

router.beforeEach(async (to) => {
    const guarded = ['profile', 'song', 'songs'];

    if (guarded.includes(to.name) && !store.state.auth.authenticated) {
        store.commit('goto', to.name);
        store.commit('flash', {type: 'warning', message: 'O acesso a esta página é restrito por isso é necessaŕio que indiques as tua credenciais.'});
        return {name: 'login'};
    }

    if (guarded.includes(to.name) && store.state.auth.expires < Date.now()) {
        await store.dispatch('auth/refresh', store.state.auth.jwt.refreshToken);
        return {name: to.name};
    }

    document.title = 'BANDA\'1000 - A maior banda rock de sempre em Portugal!';
    const title = to.meta.title;

    if (title) {
        document.title = title + ' - BANDA\'1000';
    }
});

export default router;

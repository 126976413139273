<template>
  <LogoWithClose/>
  <div class="container">
    <h1 class="display-6">Verificar Inscrição</h1>
    <div class="row">
      <div class="col-md-8 mb-4">
        <p class="lead">
          Enviámos uma mensagem para o teu email a qual contém um código que deves indicar aqui de modo a verificar a tua inscrição.
        </p>
        <p v-if="error" class="alert alert-danger">{{ error }}</p>
        <Form @submit="onSubmit">
          <div class="mb-3">
            <label for="pin" class="form-label">Código de Verificação</label>
            <Field type="text" name="pin" id="pin" class="form-control" rules="required|pin" placeholder="999999"></Field>
            <Field type="hidden" name="code" :value="$route.params.id" rules="required"></Field>
            <ErrorMessage name="pin" class="text-danger"></Errormessage>
          </div>
          <button class="btn btn-primary">Verificar Inscrição</button>
        </form>
      </div>
      <div class="col-md-4 mb-4">
        <GetHelp></Gethelp>
      </div>
    </div>
  </div>
</template>

<script>
  import LogoWithClose from '@/components/LogoWithClose.vue';
  import GetHelp from '@/components/GetHelp.vue';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import { defineRule } from 'vee-validate';
  import axios from 'axios';

  defineRule('pin', (value) => {
      const pin = parseInt(value);
      if (isNaN(pin) || 100000 > pin || 999999 < pin) {
          return 'Este valor tem de ser um número entre 100000 e 999999.';
      }

      return true;
  });

  export default {
      name: 'VerifyView',
      components: {
          LogoWithClose,
          GetHelp,
          Form,
          Field,
          ErrorMessage
      },
      data() {
          return {
              error: ''
          };
      },
      methods: {
          onSubmit(values) {
              const url = process.env.VUE_APP_API_HOST + '/verify/' + values.code;
              const data = {pin: parseInt(values.pin)};

              axios.post(url, data)
                      .then(() => {
                          this.$router.push({name: 'login'});
                      })
                      .catch(() => {
                          this.error = 'O código está incorrecto ou possivelmente já não é válido.';
                      });
          }
      }
  };
</script>
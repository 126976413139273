import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';

import 'bootstrap';
import '@/app.scss';

import { defineRule } from 'vee-validate';

defineRule('required', value => {
    if (!value || !value.length) {
        return 'Este valor é obrigatório.';
    }
    return true;
});

defineRule('email', value => {
    if (!value || !value.length) {
        return true;
    }
    const valid = String(value)
        .toLowerCase()
        .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!valid) {
    //if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value)) {
        return 'Este valor tem de ser um endereço de email válido.';
    }
    return true;
});

defineRule('digits', value => {
    if (!value || !value.length) {
        return true;
    }
    const valid = String(value).match(/^\d+$/);
    if (!valid) {
        return 'Este valor tem de conter apenas algarismos.';
    }
    return true;
});

defineRule('min', (value, [min]) => {
    if (!value || min > value.length) {
        return 'Este valor tem de conter pelo menos ' + min + ' caracteres.';
    }
    return true;
});

defineRule('max', (value, [max]) => {
    if (!value || !value.length) {
        return true;
    }
    if (max < value.length) {
        return 'Este valor não pode conter mais de ' + max + ' caracteres.';
    }
    return true;
});

defineRule('match', (value, [target, label], ctx) => {
    if (value !== ctx.form[target]) {
        return 'Este valor não coincide com o campo ' + (label || target) + '.';
    }
    return true;
});

createApp(App).use(store).use(router).mount('#app');

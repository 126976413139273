<template>
  <router-view/>

  <footer class="container">
    <div class="row">
      <div class="col-md-4 mb-2">
        <ul class="list-unstyled">
          <li>
          <router-link :to="{ name: 'home'}" class="text-light">
            Início
          </router-link>
          </li>
          <li>
          <router-link :to="{ name: 'about'}" class="text-light">
            Quem Somos
          </router-link>
          </li>
          <li>
          <router-link :to="{ name: 'faq'}" class="text-light">
            Perguntas Frequentes
          </router-link>
          </li>
          <li>
          <router-link :to="{ name: 'gallery'}" class="text-light">
            Galeria
          </router-link>
          </li>
          <li>
          <router-link :to="{ name: 'press'}" class="text-light">
            Imprensa
          </router-link>
          </li>
          <li>
          <router-link :to="{ name: 'contact'}" class="text-light">
            Contacto
          </router-link>
          </li>
        </ul>
      </div>
      <div class="col-md-4 mb-2">
        <p>
          <a href="https://www.facebook.com/banda1000pt" class="text-light mx-2">
            <i class="bi-facebook"></i>
          </a>
          <a href="https://www.instagram.com/banda1000pt/" class="text-light mx-2">
            <i class="bi-instagram"></i>
          </a>
          <a href="https://twitter.com/banda1000pt" class="text-light mx-2">
            <i class="bi-twitter"></i>
          </a>
          <a href="https://www.youtube.com/@banda1000pt" class="text-light mx-2">
            <i class="bi-youtube"></i>
          </a>
          <a href="https://open.spotify.com/artist/3DzkXk6uoZSXxIoXr543v8?si=EkIto_FSSQSRhF9gMR-Ong" class="text-light mx-2">
            <i class="bi-spotify"></i>
          </a>
          <a href="https://music.apple.com/pt/artist/banda1000/1685114238" class="text-light mx-2">
            <i class="bi-apple"></i>
          </a>
        </p>
        <p>
          <a href="mailto:info@banda1000.com" class="text-light mx-2">
            <i class="bi-envelope"></i>
            info@banda1000.com
          </a>
        </p>
      </div>
      <div class="col-md-4 mb-2">
        <router-link :to="{ name: 'home'}" class="text-light">
          <img :src="require('@/assets/b1k-logo-wt.svg')" style="width: auto; height: 1.5rem; margin-bottom: 1rem;" alt="BANDA'1000" />
        </router-link>
        <p class="small">
          &copy; BANDA'1000 é uma marca registada em Portugal no INPI.
          <br>
          Todos os direitos reservados.
        </p>
      </div>
    </div>
  </footer>
  <div class="text-dark small d-none">
    API: {{ env.apiHost }} Client: {{ env.clientId }}
  </div>
</template>

<style>
  footer {
      margin-top: 6rem;
      margin-bottom: 2rem;
  }
</style>

<script>
  export default {
      name: 'App',
      data() {
          return {
            env: {
              apiHost: process.env.VUE_APP_API_HOST,
              clientId: process.env.VUE_APP_CLIENT_ID
            }
          };
      }
  }
</script>

<template>
  <div class="text-center">
    <h3>
      Segue as nossas redes
    </h3>
    <p class="display-6">
      <a href="https://www.facebook.com/banda1000pt" class="text-facebook mx-2" title="BANDA'1000 no Facebook" target="_blank"><i class="bi-facebook"></i></a>
      <a href="https://www.instagram.com/banda1000pt/" class="text-instagram mx-2" title="BANDA'1000 no Instagram" target="_blank"><i class="bi-instagram"></i></a>
      <a href="https://twitter.com/banda1000pt" class="text-twitter mx-2" title="BANDA'1000 no Twitter" target="_blank"><i class="bi-twitter"></i></a>
      <a href="https://www.youtube.com/@banda1000pt" class="text-youtuber mx-2" title="BANDA'1000 no Youtube" target="_blank"><i class="bi-youtube"></i></a>
      <a href="https://open.spotify.com/artist/3DzkXk6uoZSXxIoXr543v8?si=EkIto_FSSQSRhF9gMR-Ong" class="text-spotify mx-2" title="BANDA'1000 no Spotify" target="_blank"><i class="bi-spotify"></i></a>
      <a href="https://music.apple.com/pt/artist/banda1000/1685114238" class="text-apple mx-2" title="BANDA'1000 no Apple Music" target="_blank"><i class="bi-apple"></i></a>
    </p>
  </div>
</template>

<script>
  export default {
      name: 'SocialNetworks'}
</script>

import axios from 'axios';

const API_HOST = process.env.VUE_APP_API_HOST;
const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;

export default {
    init() {
        const jwt = JSON.parse(localStorage.getItem('jwt'));

        if (!jwt) {
            return {
                authenticated: false,
                expires: Date.now(),
                jwt: null,
                user: null
            };
        }

        const claims = this.decode(jwt.accessToken);

        return {
            authenticated: true,
            expires: claims.exp * 1000,
            jwt,
            user: {
                id: claims.sub,
                firstName: claims.ufn,
                lastName: claims.uln,
                picture: claims.upp,
                roles: claims.rol
            }
        };
    },
    decode(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    },
    expired(token) {
        const claims = this.decode(token);
        const now = Date.now() / 1000;

        if (claims.exo && claims.exo < now) {
            return true;
        }

        return false;
    },
    claims() {
        const jwt = JSON.parse(localStorage.getItem('jwt'));

        if (!jwt) {
            return {};
        }

        return this.decode(jwt.accessToken);
    },
    headers() {
        const jwt = JSON.parse(localStorage.getItem('jwt'));

        if (!jwt) {
            return {};
        }

        return {
            Authorization: 'Bearer ' + jwt.accessToken
        };
    },
    authorize() {
        return axios.post(API_HOST + '/authorize', {clientId: CLIENT_ID})
                .then(res => {
                    if (res.data.code) {
                        return res.data;
                    }
                });
    },
    async signup(user) {
        const authorization = await this.authorize();

        const data = {
            code: authorization.code,
            clientId: CLIENT_ID,
            type: user.type,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
            confirmPassword: user.confirmPassword
        };

        return axios.post(API_HOST + '/users/signup', data)
                .then(res => {
                    return res.data;
                });
    },
    async login(user) {
        const authorization = await this.authorize();

        const data = {
            grantType: 'password',
            code: authorization.code,
            clientId: CLIENT_ID,
            email: user.email,
            password: user.password
        };

        return axios.post(API_HOST + '/token', data)
                .then(res => {
                    if (res.data.accessToken) {
                        localStorage.setItem('jwt', JSON.stringify(res.data));
                    }

                    return res.data;
                });
    },
    async refresh(token) {
        const authorization = await this.authorize();

        const data = {
            grantType: 'refresh',
            code: authorization.code,
            clientId: CLIENT_ID,
            token: token
        };

        return axios.post(API_HOST + '/token', data)
                .then(res => {
                    if (res.data.accessToken) {
                        localStorage.setItem('jwt', JSON.stringify(res.data));
                    }

                    return res.data;
                });
    },
    async reset(values) {
        const authorization = await this.authorize();

        const data = {
            code: authorization.code,
            clientId: CLIENT_ID,
            email: values.email
        };

        return axios.post(API_HOST + '/password/reset', data)
                .then(res => {
                    return res.data;
                });
    },
    password(values) {
        const data = {
            code: values.code,
            pin: Number(values.pin),
            password: values.password,
            confirmPassword: values.confirmPassword
        };
        
        return axios.post(API_HOST + '/password/change', data);
    },
    logout() {
        localStorage.removeItem('jwt');
    }
};
import { createStore } from 'vuex';
import { auth } from './auth.module.js';

export default createStore({
    modules: { auth },
    state: {
        flash: null,
        goto: null
    },
    mutations: {
        flash(state, msg) {
            state.flash = msg || null;
        },
        goto(state, name) {
            state.goto = name || null;
        }
    }
});
